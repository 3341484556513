<template>
    <div class="hard_tec_report_list_page">
        <header>
            <div class="header-main">
                <div class="title-text">
                    企业硬科技属性评价报告
                </div>
            </div>
        </header>
        <main>
            <div class="page-main">
                <div class="my_table" v-loading="downloadLoading">
                    <el-table :data="tableData" style="width: 100%" border class="custom_my_table" v-loading="loading">
                        <el-table-column align="center" type="index" label="序号" width="56" />
                        <el-table-column align="left" prop="nameCn" label="企业名称" width="720">
                        </el-table-column>
                        <el-table-column align="left" prop="createdDate" label="创建时间" width="200">
                        </el-table-column>
                        <el-table-column align="center" fixed="right" label="操作" width="160">
                            <template slot-scope="scope">
                                <div class="operation">
                                    <span @click="toDownload(scope.row)">下载</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="my_pagination">
                        <el-pagination background @size-change="pagination.handleSizeChange"
                            @current-change="pagination.handleCurrentChange" :current-page="pagination.currentPage"
                            :page-sizes="[10, 20, 30, 40]" :page-size="pagination.pageSize"
                            layout="total, sizes, jumper, prev, pager, next" :total="pagination.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </main>
        <footer>
            <div class="foot-main">
                <div class="left">
                    <span>评估单位：</span>
                    <div class="img-box" @click="e => clickImg(e)">
                        <img src="https://images.zzt.com.cn/officialWebsite/hard-tec-report-foot-icon.png">
                    </div>
                </div>
                <div class="right">
                    <div class="code-item">
                        <img src="https://images.zzt.com.cn/ox/2022/04/26/concatc2.png" class="img-box">
                        <span>扫码联系客服</span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import * as api from '@/api'
export default {
    data() {
        return {
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
                handleSizeChange: '',
                handleCurrentChange: '',
            },
            temporaryRow: {},
            downloadLoading: false,
        }
    },
    methods: {
        toDownload(row) {
            this.downloadLoading = true
            let params = {
                taskId: row.taskId,
            }
            api.getHardDownloadUrl(params).then(({ success, data }) => {
                if (success) {
                    this.downloadLoading = false
                    this.downloadFile(data.data)
                } else {
                    this.downloadLoading = false
                    this.$message.error(data.message)
                }
            }).catch(() => {
                this.downloadLoading = false
                this.$message.error('接口响应超时，下载失败！')
            })
        },
        downloadFile(url, fileName = '') {
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName ? fileName : url.split('/').pop(); // 或者使用自定义文件名  
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.downloadLoading = false
            this.$message.success('文件下载完成')
        },
        toDelete() {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getTableList() {
            this.loading = true
            let params = {
                pageNo: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
            }
            api.pageEnterpriseHardIndicatorReportList(params).then(({ data: { data } }) => {
                this.loading = false
                this.pagination.total = parseInt(data.total || 0)
                this.tableData = data.records
            }).catch(err => {
                console.log(err)
            })
        },
        clickImg(e) {
            if (e.offsetX < 216) {
                window.open('https://www.miit.gov.cn/', '_blank')
            } else {
                const route = this.$router.resolve({
                    path: '/home'
                })
                window.open(route.href, '_blank')
            }
        },
    },
    mounted() {
        this.pagination.handleSizeChange = (e) => {
            this.pagination.pageSize = e
            this.getTableList()
        }
        this.pagination.handleCurrentChange = (e) => {
            this.pagination.currentPage = e
            this.getTableList()
        }
        this.getTableList()
    },
}
</script>

<style lang="scss" scoped>
.hard_tec_report_list_page {
    header {
        background-color: #fff;
        border-bottom: 1px solid #eee7e7;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;

        .header-main {
            height: $header-height;
            width: $container-width;
            margin: 0 auto;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;

            .title-text {
                font-size: Translate(24px);
                color: #333333;
                text-align: center;
                font-weight: 500;
            }
        }
    }

    main {
        margin-top: $header-height;
        overflow: hidden;
        min-height: calc(100vh - $header-height - Translate(240px));
        height: 100%;
        margin-bottom: Translate(54px);

        .page-main {
            //   height: calc(100vh - $header-height);
            width: $container-width;
            text-align: left;
            margin: 0 auto;
        }
    }

    .page_title {
        height: Translate(76px);
        line-height: Translate(76px);
        font-size: Translate(18px);
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .my_table {
        padding: Translate(24px) Translate(20px) Translate(92px) Translate(20px);
        background: #FFFFFF;
        border-radius: Translate(5px);
        margin-top: Translate(20px);
        min-height: Translate(696px);
        box-sizing: border-box;


        .my_pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: Translate(20px);
        }

        .operation {
            color: #4088eb;
            display: flex;
            justify-content: center;

            span {
                cursor: pointer;
            }
        }
    }
}

.mr-10 {
    margin-right: Translate(10px);
}

footer {
    width: 100%;
    background: #2E354D;
    display: flex;
    justify-content: center;

    .foot-main {
        width: $container-width;
        height: Translate(240px);
        color: #fff;
        display: flex;
        justify-content: space-between;
        padding-top: Translate(50px);
        box-sizing: border-box;

        .left {
            text-align: left;

            .img-box {
                width: Translate(324px);
                height: Translate(24px);
                margin-top: Translate(12px);

                img {
                    width: Translate(324px);
                    height: Translate(24px);
                }
            }
        }

        .right {
            .code-item {
                display: flex;
                flex-direction: column;

                img {
                    box-sizing: border-box;
                    width: Translate(110px);
                    height: Translate(110px);
                    padding: Translate(7px);
                    background-color: #fff;
                }

                span {
                    padding-top: Translate(12px);
                    font-size: Translate(12px);
                    color: #999999;
                    line-height: Translate(17px);
                    font-weight: 400;
                }
            }
        }
    }
}
</style>